* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.contact-us,
.terms,
.testimonials,
.session,
.content {
  display: flex;
  height: 90vh;
  align-items: top;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url("./assets/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.session {
  background-image: url("./assets/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.reiki {
  background-image: url("./assets/images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.content {
  /* background-image: url("./assets/images/img-6.jpg"); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 20px;
}

/* Header/Blog Title */
.header {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: white;
}

.contact-us {
  background-image: url("./assets/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  font-size: 100px;
}
