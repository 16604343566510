* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

body {
  font-family: Arial;
  padding: 10px;
  background: white;
}

/* Header/Blog Title */
.header {
  background: url('../assets/images/head.jpg') center center/cover no-repeat;
  padding: 30px;
  text-align: center;
  /* background: white; */
}

.header h1 {
  background: url('../assets/images/head.jpg') center center/cover no-repeat;
  font-size: 50px;
  color: #770d0d;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {   
  float: left;
  width: 75%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  background-color:#cdbbfa;
  padding-left: 20px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.card {
  /* background-color: white; */
  padding: 20px;
  margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer
.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
  margin-top: 20px;
} */

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {   
    width: 100%;
    padding: 0;
  }
}

/* Responsive layout - when the screen is less than 400px wide, make the navigation links stack on top of each other instead of next to each other */
@media screen and (max-width: 400px) {
  .topnav a {
    float: none;
    width: 100%;
  }
}
